import _isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";
import { Avatar } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import Slider from "react-slick";
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import {
  getBuildingRecord,
  getPartnerDetails,
  getBuildingRecordLocal,
  getCustomerPreviousSalesPopupData,
} from "../../store/actions/buildings.actions";
import { toastMessage } from "../../utils/utils";

import nopath from "../../assets/img/NoPath.png";
import interaction from "../../assets/img/interaction.svg";
import copyButton from "../../assets/img/copy-button.svg";
import SideMenu from '../../component/SideMenu';
import Header from '../../component/Header';
import SearchInput from '../../component/Search';
import Footer from '../../component/Footer';
import Modal from '../../component/Modal';

const Buildings = ({ history, logout, loading }) => {

  const [active, setActive] = useState(0);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const location = useLocation();
  const { buildingData, customerdata,
    previousSalesPopup, partnerData, nearByBuildings } = useSelector(state => state.building);
  const buildingInfo = JSON.parse(localStorage.getItem('buildingInfo'));
  const bd = JSON.parse(localStorage.getItem('bd'));

  useEffect(() => {

    if (_isEmpty(bd)) {
      navigate("/")
    }

    if (!_isEmpty(bd)) {
      if (navigator.onLine)
        dispatch(getBuildingRecord({ "property_code": bd }))
      else
        dispatch(getBuildingRecordLocal(buildingInfo));
    }
    if (_isEmpty(partnerData)) {
      dispatch(getPartnerDetails());
    }
  }, []);

  const settings = {
    "dots": true,
    "infinite": false,
    "speed": 800,
    "slidesToShow": 3,
    "slidesToScroll": 3,
    "initialSlide": 0,
    "responsive": [
      {
        "breakpoint": 1194,
        "settings": {
          "slidesToShow": 2,
          "slidesToScroll": 2,
        }
      }
    ]
  };

  const getPreviousSalesPopupData = (e) => {
    const bodydata = {
      'customer_experience_interaction_id': e.customer_experience_interaction_id,
      'customer_experience_interaction_date': e.customer_experience_interaction_date,
    }
    dispatch(getCustomerPreviousSalesPopupData(bodydata));
  }

  const copyAddress = () => {
    var copyText = document.getElementById("address-block");
    navigator.clipboard.writeText(copyText.textContent);

    toastMessage("info", 'Address copied to clipboard');
  }

  const nearbyBuildingRedirect = (e) => {
    dispatch(getBuildingRecord({ "property_code": e.property_code }));
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  const redirectPartner = (e) => {
    if (e.Marketplace !== "No") window.open(e.Marketplace_Url)
  }

  return (
    <React.Fragment>

      <div className='container-fluid'>
        <div className="sidenav">
          <SideMenu
            page="building"
          />
        </div>
        <div className="main pb-100">
          <div className="row">
            <Header
              title='Buildings'
            />
          </div>
          {!_isEmpty(buildingData) ?
            <div style={{ height: `calc(100 % - 5px)` }}>
              <div className="row">
                <div className="container-search">
                  <SearchInput
                    page="building"
                  />
                </div>
              </div>
              <div className="row">
                <div className='white-box pd-35 pd-white-box'>
                  <div className='col-xl-2 col-lg-3 col-md-3 col-sm-3'>
                    <img src={nopath} alt="ContactIcon" />
                  </div>
                  <div className='col-xl-10 col-lg-9 col-md-9 col-sm-9'>
                    <div className='row'>
                      <div className='col'>
                        <label className='title'>{buildingData.buildingName}</label>
                      </div>
                    </div>
                    <div className='row pt-15'>
                      <div className='col-6'>
                        <div className='description copy'>
                          <img src={copyButton} alt="copyButton" className="copy-icon cursor" onClick={copyAddress} />
                          <span id="address-block">
                            {buildingData.buildingAdress.adress1} <br></br>
                            {buildingData.buildingAdress.adress2}, {buildingData.buildingAdress.adress3} <br></br>
                            {buildingData.buildingAdress.city}, &nbsp;
                            {buildingData.buildingAdress.state}, <br></br>
                            {buildingData.buildingAdress.postalCode}  <br></br>
                            {buildingData.buildingAdress.country}
                          </span>
                        </div>
                      </div>
                      <div className='col-6'>
                        <label className='description'>Enterprise ID : {buildingData.propertInfo.propertCode}</label>
                        <br></br>
                        <label className='description'>Building status : <span style={{ color: "#008e5b" }}>Active</span></label>
                        <br></br>
                        <label className='title mt-10'>Customers in this building</label>
                        <br></br>
                        <div className="card-para">
                          {buildingData.buildingTenantContactDetails ?
                            buildingData.buildingTenantContactDetails.map((i, e) => {
                              return (
                                <span key={`${e}-customer`} className='font-14'>
                                  {i.customerName}
                                  {e != buildingData.buildingTenantContactDetails.length - 1 &&
                                    i.customerName ? ', ' : ''}
                                </span>
                              )
                            })
                            : ''}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='bg-white'>
                  <hr className='hr'></hr>
                  <div className='customer-tab'>
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                      {buildingData.buildingTenantContactDetails ?
                        buildingData.buildingTenantContactDetails.map((i, e) => {
                          return (
                            <li key={`${e}-tabs`} className="nav-item" role="presentation">
                              {i.customerName ?
                                <button className={`nav-link tab-font ${active === e ? 'active' : ''}`} id="home-tab" data-bs-toggle="tab" data-bs-target={`#tabs-${i.customerName.split(' ')[0].replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '_')}-id`} type="button" role="tab" aria-controls="home" aria-selected="true">
                                  {buildingData.buildingTenantContactDetails.length > 3 ?
                                    i.customerName.split(' ')[0]
                                    :
                                    i.customerName}
                                </button>
                                : ''}
                            </li>
                          )
                        })
                        : ''}
                    </ul>
                  </div>
                </div>
                <div className="tab-content" id="myTabContent">
                  {buildingData.buildingTenantContactDetails.length ?
                    buildingData.buildingTenantContactDetails.map((i, e) => {
                      return (
                        <div key={`${e}-divs`} className={`tab-pane fade show ${active === e ? 'active' : ''}`} id={`tabs-${i.customerName ? i.customerName.split(' ')[0].replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '_') : ''}-id`} role="tabpanel" aria-labelledby="home-tab">
                          <div>
                            <div className='row mt-25'>
                              <div className='col-lg-12 col-md-12 col-sm-12 cust-title-row'>
                                <label>{i.customerName}</label>
                              </div>
                            </div>
                            <div className='row mt-25'>
                              <div className='col-lg-6 col-md-12 col-sm-12'>
                                <div className='grid-col-left'>
                                  <label className='grid-title'>Past Interactions with {i.customerName ? i.customerName.split(' ', 1).join(' ') : ' '}</label>
                                  <a href={`${window.SALESFORCE_URL}${i.customerId}/view`} target="_blank" className='grid-title float-end'>
                                    <Avatar>+</Avatar> Add Interaction</a>
                                  <div className='overflow'>
                                    {(!_isEmpty(customerdata)) && customerdata[i.customerName] ?
                                      customerdata[i.customerName]['custlist'].map((e, j) => {
                                        return (
                                          <div key={`${j}-previous-sales`} className='div-sales cursor'
                                            data-bs-toggle="modal" data-bs-target="#modalopen"
                                            onClick={() => getPreviousSalesPopupData(e)}>
                                            <label className='title'>{e.subject} &nbsp; <img src={interaction} alt="interaction" /></label><br></br>
                                            <p className='sub-title'>
                                              {e.customer_experience_interaction_comments_text}
                                            </p>
                                            <table style={{ marginTop: '10px' }}>
                                              <thead>
                                                <tr className='table-row-head'>
                                                  <td style={{ width: "42%" }}>Prologis Representative</td>
                                                  <td style={{ width: "22%" }}>Pitch Status</td>
                                                  <td style={{ width: "20%" }}>Sentiment</td>
                                                  <td>Date</td>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                <tr className='table-row-data'>
                                                  <td>{e.customer_experience_interaction_created_by_name}</td>
                                                  <td>{e.customer_experience_interaction_status}</td>
                                                  <td>{e.customer_experience_interaction_overall_sentiment_category}</td>
                                                  <td>{e.customer_experience_interaction_date}</td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </div>
                                        )
                                      })
                                      : <label className="text-normal">No Interactions Available</label>}
                                  </div>
                                </div>
                              </div>
                              <div className='col-lg-6 col-md-12 col-sm-12 mt-sm-30'>
                                <div className='grid-col-right'>
                                  <label className='grid-title'>Unit Specifications</label>
                                  <div className='overflow'>
                                    <label className='text-bold'>Total space : &nbsp;&nbsp;</label><span className='text-normal'>{i.buildingMarketHierarchy.statisticalMetricsNetRentableArea} &nbsp; SQM</span><br></br>
                                    {i.buildingUnitCharacteristics.fireSuppressionSystem ?
                                      <div>
                                        <label className='text-bold'>Fire Suppression System : &nbsp;&nbsp;</label><span className='text-normal'> {i.buildingUnitCharacteristics.fireSuppressionSystem}</span>
                                      </div>
                                      : ''}
                                    {i.buildingUnitCharacteristics.unitHvacWarehouseType ?
                                      <div>
                                        <label className='text-bold'>Warehouse Type : &nbsp;&nbsp;</label><span className='text-normal'> {i.buildingUnitCharacteristics.unitHvacWarehouseType}</span>
                                      </div>
                                      : ''}
                                    {i.buildingUnitCharacteristics.unitKVAOwnerName ?
                                      <div>
                                        <label className='text-bold'>KVA Owner Name : &nbsp;&nbsp;</label><span className='text-normal'> {i.buildingUnitCharacteristics.unitKVAOwnerName}</span>
                                      </div>
                                      : ''}
                                    {i.buildingUnitCharacteristics.warehouseLightingType ?
                                      <div>
                                        <label className='text-bold'>Warehouse Lighting : &nbsp;&nbsp; </label><span className='text-normal'>{i.buildingUnitCharacteristics.warehouseLightingType}</span>
                                      </div>
                                      : ''}
                                    {i.buildingUnitCharacteristics.unitMezzaineLightingType ?
                                      <div>
                                        <label className='text-bold'>Mezzaine Lighting Type : &nbsp;&nbsp; </label><span className='text-normal'>{i.buildingUnitCharacteristics.unitMezzaineLightingType}</span>
                                      </div>
                                      : ''}
                                    {i.buildingUnitCharacteristics.unitOfficeLightingType ?
                                      <div>
                                        <label className='text-bold'>Office Lighting Type : &nbsp;&nbsp; </label><span className='text-normal'>{i.buildingUnitCharacteristics.unitOfficeLightingType}</span>
                                      </div>
                                      : ''}
                                    {i.buildingUnitCharacteristics.dockHighDoors ?
                                      <div>
                                        <label className='text-bold'>Dock Doors : &nbsp;&nbsp; </label><span className='text-normal'>{i.buildingUnitCharacteristics.dockHighDoors}</span>
                                      </div>
                                      : ''}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className='row mt-25'>
                              <div className='col-lg-6 col-md-12 col-sm-12'>
                                <div className='grid-col-left'>
                                  <label className='grid-title'>{i.customerName} Contacts</label><br></br>
                                  <div className='overflow'>
                                    {(!_isEmpty(customerdata)) && customerdata[i.customerName] ?
                                      customerdata[i.customerName]['contactDetails'].map((e, j) => {
                                        return (
                                          <div key={`representatives-${j}`} className='rep-grid-box'>
                                            <div className='initials'>
                                              <span className='text'>
                                                {e.who_full_name.split(" ").map((n) => n[0]).join("")}
                                              </span>
                                            </div>
                                            <div className='div-content width-20 w-20'>
                                              <label className='title'>{e.who_full_name}</label><br></br>
                                              <span className='sub-title'>{e.who_title}</span>
                                            </div>
                                            <div className='div-content width-33 w-45'>
                                              <label className='text-bold'>Mail:&nbsp; </label>
                                              <a href={`mailto:${e.who_email}`}>
                                                <span className='text-normal color-blue bold underline'>{e.who_email}</span>
                                              </a><br>
                                              </br>
                                              <label className='text-bold'>Tel:&nbsp;  </label>
                                              <a href={`tel:${e.who_phone}`}>
                                                <span className='text-normal color-blue bold underline'>{e.who_phone}</span>
                                              </a>
                                            </div>
                                            <div className='div-content width-25'>
                                              <label className='text-bold'>Decision Maker: &nbsp; </label><span className='text-normal'>Yes</span><br>
                                              </br>
                                            </div>
                                          </div>
                                        )
                                      })
                                      : <label className="text-normal">No Representatives Available</label>}
                                  </div>
                                </div>
                              </div>
                              <div className='col-lg-6 col-md-12 col-sm-12 mt-sm-30'>
                                <div className='grid-col-right'>
                                  <label className='grid-title'>Prologis Partners</label><br>
                                  </br>
                                  <div className='overflow'>
                                    {partnerData.length ?
                                      partnerData.map((i, e) => {
                                        return (
                                          <div key={`${e}-partner-data`} className='partner-grid-box cursor' onClick={() => redirectPartner(i)}>
                                            <label className='text-bold'>{i['Category 1']} - {i['Category 2']}</label><br></br>
                                            <span className='text-normal'>{i.Vendor}</span>
                                          </div>
                                        )
                                      })
                                      :
                                      <label className="text-normal">No Data Available</label>
                                    }
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className='row mt-25'>
                              <div className='col-lg-6 col-md-12 col-sm-12'>
                                <div className='grid-col-left'>
                                  <label className='grid-title'>Equipment Installed</label>
                                  <div className='overflow'>
                                    {i.buildingUnitCharacteristics.warehouseLightingType && i.buildingUnitCharacteristics.warehouseLightingType.toLowerCase() === 'led' ?
                                      <div>
                                        <label className='text-bold'>Warehouse Lighting : &nbsp;&nbsp; </label><span className='text-normal'>{i.buildingUnitCharacteristics.warehouseLightingType}</span><br></br>
                                      </div>
                                      :
                                      ''
                                    }
                                    {i.buildingUnitCharacteristics.unitOfficeLightingType && i.buildingUnitCharacteristics.unitOfficeLightingType.toLowerCase() === 'led' ?
                                      <div>
                                        <label className='text-bold'>Office Lighting Type :  &nbsp;&nbsp;</label><span className='text-normal'>{i.buildingUnitCharacteristics.unitOfficeLightingType}</span>
                                      </div>
                                      :
                                      ''
                                    }
                                    {i.buildingUnitCharacteristics.warehouseLightingType &&
                                      i.buildingUnitCharacteristics.warehouseLightingType.toLowerCase() !== 'led' &&
                                      i.buildingUnitCharacteristics.unitOfficeLightingType &&
                                      i.buildingUnitCharacteristics.unitOfficeLightingType.toLowerCase() !== 'led' ?
                                      <div>
                                        <label className="text-normal">No Data Available</label>
                                      </div>
                                      :
                                      ''
                                    }
                                    {i.buildingUnitCharacteristics.warehouseLightingType == null &&
                                      i.buildingUnitCharacteristics.unitOfficeLightingType == null ?
                                      <div>
                                        <label className="text-normal">No Data Available</label>
                                      </div>
                                      :
                                      ''
                                    }
                                  </div>
                                </div>
                              </div>
                              <div className='col-lg-6 col-md-12 col-sm-12 mt-sm-30'>
                                <div className='grid-col-right'>
                                  <label className='grid-title'>Recomended Products</label>
                                  <div className='overflow'>
                                    <span className='pop-value pop-pill mr-10'> Lightingz </span>
                                    <span className='pop-value pop-pill mr-10'> LightSmart </span>
                                    <span className='pop-value pop-pill mr-10'> Racking </span>
                                    <span className='pop-value pop-pill mr-10'> Forklift </span>
                                    <span className='pop-value pop-pill mr-10'> HVAC Equipment </span>
                                    <br></br>
                                    <span className='pop-value pop-pill mr-10'> Services </span>
                                    <span className='pop-value pop-pill mr-10'> Smart Building </span>
                                    <span className='pop-value pop-pill mr-10'> Finishes </span>
                                    <span className='pop-value pop-pill mr-10'> Fans </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    })
                    : <label className="text-normal">No Data Available</label>}
                </div>
                <div className='container'>
                  <div className='row mt-25'>
                    <div className='col-lg-12 col-md-12 col-sm-12'>
                      <div className='grid-col-left pb-30'>
                        <div className="row">
                          <div className="col-6">
                            <label className='grid-title'>Nearby Buildings</label>
                          </div>
                        </div>
                        <div className='row mt-10 mr-30 ml-30'>
                          {!_isEmpty(nearByBuildings) ?
                            <Slider {...settings}>
                              {nearByBuildings.map((i, e) => {
                                return (
                                  <div className="col-sm-4 cursor" key={`carousel-card${e}`} onClick={() => nearbyBuildingRedirect(i)}>
                                    <div className="card mr-15">
                                      <div className="card-body">
                                        <div className="row">
                                          <div className='col-lg-3 col-md-3 col-sm-12 '>
                                            <img src={nopath} className="w-99 bblr" alt="ContactIcon" />
                                          </div>
                                          <div className='col-lg-6 col-md-6 col-sm-12 '>
                                            <label className='nb-t mt-10'>{i.building_name}</label>
                                            <p className="nb-des">{i.building_full_address_text}</p>
                                          </div>
                                          <div className='col-lg-3 col-md-3 col-sm-12 '>
                                            <label className='nb-t mt-10'></label>
                                            <p className="grid-title">{i.distance === 0 ? '0 Mi' : i.distance ? i.distance.toFixed(2) + ' Mi' : ''}</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )
                              })}
                              <div className="col-sm-4 cursor">
                                <div className="card mr-15">
                                  <div className="card-body">
                                    <div className="row">
                                      <div className='col-lg-3 col-md-3 col-sm-12 '>
                                        <img src={nopath} className="w-99 bblr visibility" alt="ContactIcon" />
                                      </div>
                                      <div className='col-lg-6 col-md-6 col-sm-12 '>
                                        <label className='nb-t mt-10'></label>
                                        <p className="grid-title text-center"><a className="grid-title" href="/">Search more Buildings</a></p>
                                      </div>
                                      <div className='col-lg-3 col-md-3 col-sm-12 '>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Slider>
                            :
                            <label className="text-normal text-center">No Nearby Buildings Available</label>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div >
            : bd ? <label className="text-normal">No Data Available</label> : navigate("/")}
        </div>
        {previousSalesPopup ?
          <Modal
            id="modalopen"
            className="modal-dialog modal-xl"
            buttonSec="Dismiss"
            buttonPrim="Print"
            data={previousSalesPopup}
          />
          :
          ''
        }
        <Footer page="building" />
      </div>

    </React.Fragment >
  )
}

Buildings.propTypes = {
  history: PropTypes.object,
  loading: PropTypes.bool,
  logout: PropTypes.func,
};

export default Buildings;
