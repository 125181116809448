import { notification } from "antd";

export const to = promise => promise.then(data => [null, data]).catch(err => [err]);

export const throwError = (errMessage, log) => {
    if (log === true) {
        // eslint-disable-next-line no-console
        console.error(errMessage);
    }
    throw errMessage;
};

export const toastMessage = (type, description, title, obj = {}, onClick = null) => {
    const object = obj;
    object.key = Date.now();

    object.message = description || object.message;
    object.onClick = () => {
        if (onClick) onClick();
        notification.close(object.key);
    };

    return notification.open({
        ...object,
    });
};

export const getSearchListQuery = query => {
    let params = '?';
    if (query) {
        for (let key in query) {
            params += key + '=' + query[key] + '&'
        }
    }

    return params.substring(0, params.length - 1);
};

export const convertDate = (d) => {
    return (
        d.constructor === Date ? d :
            d.constructor === Array ? new Date(d[0], d[1], d[2]) :
                d.constructor === Number ? new Date(d) :
                    d.constructor === String ? new Date(d) :
                        typeof d === "object" ? new Date(d.year, d.month, d.date) :
                            NaN
    );
};

export const compareDate = (d1, d2) => {
    return (isFinite(d1 = convertDate(d1).valueOf()) &&
        isFinite(d2 = convertDate(d2).valueOf()) ?
        (d1 > d2) - (d1 < d2) :
        NaN
    );
};

export const inDateRange = (d, start, end) => {
    return (
        isFinite(d = convertDate(d).valueOf()) &&
            isFinite(start = convertDate(start).valueOf()) &&
            isFinite(end = convertDate(end).valueOf()) ?
            start <= d && d <= end :
            NaN
    );
};
