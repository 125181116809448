import ACTIONS from "./types.actions";
import visitsService from "../../services/visits.services";
import { to, throwError, compareDate } from "../../utils/utils";

export function visitsData(data) {
    return { type: ACTIONS.GET_VISITS_DATA, data };
}

export function setUpcomingData(data) {
    data.sort((a, b) => { return new Date(a.dateTime) - new Date(b.dateTime) });
    return { type: ACTIONS.GET_UPCOMING_DATA, data };
}

export function setPastData(data) {
    data.sort((a, b) => { return new Date(a.dateTime) - new Date(b.dateTime) });
    return { type: ACTIONS.GET_PAST_DATA, data };
}

export function getVisitsData() {
    return async dispatch => {
        try {
            const [err, response] = await to(visitsService.getVisitsData());
            if (err) throwError(err);

            const date = new Date();
            let upcoming = [], past = [];

            if (response.message.length > 0) {
                response.message.map((i) => {
                    const d1 = new Date(i.start.dateTime + 'Z');

                    let _t = {
                        'cPerson': i.organizer.emailAddress.name,
                        'visiType': i.location.displayName,
                        'subject': i.subject,
                        'dateTime': new Date(i.start.dateTime + 'Z'),
                        'address': i.location.address,
                        'online': i.onlineMeeting ? i.onlineMeeting.joinUrl :  i.location.uniqueId,
                        'status': i.responseStatus
                    };

                    if (compareDate(d1, date) === -1) {
                        past.push(_t);
                    }

                    upcoming.push(_t);
                    return null;
                })
            }

            dispatch(visitsData({ 'upcoming': upcoming, 'past': past, }));
            dispatch(setUpcomingData(upcoming.filter((i) => {
                return new Date(i.dateTime + 'Z').getFullYear() === new Date().getFullYear()
                    && new Date(i.dateTime + 'Z').getDate() === new Date().getDate()
                    && new Date(i.dateTime + 'Z').getMonth() === new Date().getMonth();
            })));

            dispatch(setPastData(past.filter((i) => {
                return new Date(i.dateTime + 'Z').getFullYear() === new Date().getFullYear()
                    && new Date(i.dateTime + 'Z').getDate() === new Date().getDate() - 1
                    && new Date(i.dateTime + 'Z').getMonth() === new Date().getMonth();
            })));
        } catch (error) {
            throwError(error);
        }
    };
}
