import _isEmpty from "lodash/isEmpty";
import ACTIONS from "./types.actions";
import buildingService from "../../services/buildings.services";
import { to, throwError } from "../../utils/utils";

export function buildingData(data) {
    return { type: ACTIONS.BUILDING_DATA, data };
}

export function nearbyBuildings(data) {
    return { type: ACTIONS.NEAR_BY_BUILDING_DATA, data };
}

export function searchBuildingData(data) {
    return { type: ACTIONS.SEARCH_BUILDING_DATA, data };
}

export function partnerData(data) {
    return { type: ACTIONS.PARTNER_DATA, data };
}

export function customerPreviousSalesData(data) {
    return { type: ACTIONS.PREVIOUS_SALES_DATA, data };
}

export function customerPreviousSalesPopupData(data) {
    return { type: ACTIONS.PREVIOUS_SALES_POPUP_DATA, data };
}

export function subMarketDetails(data) {
    return { type: ACTIONS.GET_SUB_MARKET_DETAILS, data };
}

export function getBuildingData(body) {
    return async dispatch => {
        dispatch(searchBuildingData([]));
        try {
            const [err, response] = await to(buildingService.getBuildingData(body));
            if (err) throwError(err);
            dispatch(searchBuildingData(response.data));
        } catch (error) {
            throwError(error);
        }
    };
}

export function getBuildingRecord(body) {
    return async dispatch => {
        try {
            dispatch(customerPreviousSalesData([]));
            const [err, response] = await to(buildingService.getBuildingData(body));
            if (err) throwError(err);
            dispatch(buildingData(response.data[0]));
            localStorage.setItem('buildingInfo', JSON.stringify(response.data[0]));
            dispatch(getCustomerPreviousSalesData(response.data[0].propertInfo.propertCode));
            dispatch(getNearbyBuildingData(response.data[0].buildingLocation, response.data[0].propertInfo.propertCode));
        } catch (error) {
            throwError(error);
        }
    };
}

export function getBuildingRecordLocal(data) {
    return async dispatch => {
        try {
            dispatch(customerPreviousSalesData([]));
            dispatch(buildingData(data));
            dispatch(getCustomerPreviousSalesData(data.propertInfo.propertCode));
            dispatch(getNearbyBuildingData(data.buildingLocation, data.propertInfo.propertCode));
        } catch (error) {
            throwError(error);
        }
    };
}

export function getPartnerDetails() {
    return async dispatch => {
        try {
            const [err, response] = await to(buildingService.getPartnerDetails());
            if (err) throwError(err);
            dispatch(partnerData(response));
        } catch (error) {
            throwError(error);
        }
    };
}

export function getCustomerPreviousSalesData(data) {
    return async dispatch => {
        try {
            let body = {
                'property_code': data,
            }
            const [err, response] = await to(buildingService.getCustomerPreviousSalesData(body));
            if (err) throwError(err);
            dispatch(customerPreviousSalesData(response));
        } catch (error) {
            throwError(error);
        }
    };
}

export function getCustomerPreviousSalesPopupData(data) {
    return async dispatch => {
        try {
            const [err, response] = await to(buildingService.getCustomerPreviousSalesPopupData(data));
            if (err) throwError(err);
            dispatch(customerPreviousSalesPopupData(response))
        } catch (error) {
            throwError(error);
        }
    };
}

export function getNearbyBuildingData(data, id) {
    return async dispatch => {
        try {
            dispatch(searchBuildingData([]));
            let body = {
                "coords": {
                    "latitude": data.lat,
                    "longitude": data.log
                }
            };
            const [err, response] = await to(buildingService.getNearbyBuildingData(body));
            if (err) throwError(err);

            if (id)
                dispatch(nearbyBuildings(!_isEmpty(response) ? response.nearestBuildings.filter((i, e) => { return i.property_code !== id && e <= 10 }) : []));
            else
                dispatch(searchBuildingData(!_isEmpty(response) ? response.nearestBuildings : []));

        } catch (error) {
            throwError(error);
        }
    };
}

export function getMarketData(body) {
    return async dispatch => {
        try {
            const [err, response] = await to(buildingService.getMarketData(body));
            if (err) throwError(err);
            dispatch(subMarketDetails(!_isEmpty(response) ? response.data.filter((i) => { return i.submarket_name }) : []));
        } catch (error) {
            throwError(error);
        }
    };
}