import _isEmpty from "lodash/isEmpty";
import { DatePicker } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";

import {
    getVisitsData,
    setUpcomingData,
    setPastData,
} from "../../store/actions/visits.actions";

import { inDateRange } from "../../utils/utils";

import SideMenu from '../../component/SideMenu';
import Header from '../../component/Header';
import Footer from '../../component/Footer';
import calendarIcon from "../../assets/img/calendar.svg";

const { RangePicker } = DatePicker;

function Visits() {

    const dispatch = useDispatch();
    const [active, setActive] = useState('today');
    const [activePast, setActivePast] = useState('yesterday');
    const [showDatePicker, setDatePicker] = useState('hide');
    const [showPastDatePicker, setPastDatePicker] = useState('hide');
    const [upcomingDateRange, setUpcomingDateRange] = useState([]);
    const [pastDateRange, setPastDateRange] = useState([]);
    const { visitsData, upcoming, past } = useSelector(state => state.visits);

    useEffect(() => {
        dispatch(getVisitsData());
    }, []);

    const disabledDate = current => {
        // Can not select days before today and today
        return current && current < moment().endOf('day');
    };

    const filterUpcomingDate = (e) => {
        const d = new Date();
        let _temp = [];
        setDatePicker('hide');
        setUpcomingDateRange([]);
        if (e.target.innerText === 'TODAY') {
            setActive('today');

            _temp = visitsData.upcoming.filter((i) => {
                return new Date(i.dateTime).getFullYear() === d.getFullYear()
                    && new Date(i.dateTime).getDate() === d.getDate()
                    && new Date(i.dateTime).getMonth() === d.getMonth();
            });
        } else if (e.target.innerText === 'TOMORROW') {
            setActive('tomorrow');

            _temp = visitsData.upcoming.filter((i) => {
                return new Date(i.dateTime).getFullYear() === d.getFullYear()
                    && new Date(i.dateTime).getDate() === d.getDate() + 1
                    && new Date(i.dateTime).getMonth() === d.getMonth();
            });
        } else if (e.target.innerText === 'THIS WEEK') {
            setActive('thisWeek');
            const endDate = new Date(d.setDate(d.getDate() - d.getDay() + 7));

            _temp = visitsData.upcoming.filter((i) => {
                return inDateRange(new Date(i.dateTime), new Date(), endDate)
            });
        } else if (e.target.innerText === 'THIS MONTH') {
            setActive('thisMonth');
            const endDate = new Date(new Date(d.getFullYear(), d.getMonth() + 1, 0));

            _temp = visitsData.upcoming.filter((i) => {
                return inDateRange(new Date(i.dateTime), new Date(), endDate)
            });
        } else {
            setActive('customeUpcoming');
            setDatePicker('show-flex');
        }

        dispatch(setUpcomingData(_temp));
    }

    const filterPastDate = (e) => {
        const d = new Date();
        let _temp = [];
        setPastDatePicker('hide');
        setPastDateRange([]);
        if (e.target.innerText === 'YESTERDAY') {
            setActivePast('yesterday');

            _temp = visitsData.past.filter((i) => {
                return new Date(i.dateTime).getFullYear() === d.getFullYear()
                    && new Date(i.dateTime).getDate() === d.getDate() - 1
                    && new Date(i.dateTime).getMonth() === d.getMonth();
            });
        } else if (e.target.innerText === 'LAST WEEK') {
            setActivePast('lastWeek');

            var startDate = new Date(new Date().setDate(new Date().getDate() - (new Date().getDay() - 1) - 7));
            var endDate = new Date().setTime(new Date().getTime() - (new Date().getDay() ? new Date().getDay() : 7) * 24 * 60 * 60 * 1000);

            _temp = visitsData.past.filter((i) => {
                return inDateRange(new Date(i.dateTime), new Date(startDate), new Date(endDate));
            });

        } else if (e.target.innerText === 'LAST MONTH') {
            setActivePast('lastMonth');

            const startDate = new Date(d.getFullYear(), d.getMonth() - 1, 1);
            const endDate = new Date(new Date(d.getFullYear(), d.getMonth(), 0));

            _temp = visitsData.past.filter((i) => {
                return inDateRange(new Date(i.dateTime), startDate, endDate);
            });
        } else {
            setActivePast('customPastFilter');
            setPastDatePicker('show-flex');
        }

        dispatch(setPastData(_temp));
    }

    const setCustomUpcomingDate = (e) => {
        setUpcomingDateRange(e)

        const _temp = visitsData.upcoming.filter((i) => {
            return inDateRange(new Date(i.dateTime), e[0]._d, e[1]._d);
        });

        dispatch(setUpcomingData(_temp));
    };

    const setCustomPastDate = (e) => {
        setPastDateRange(e)

        const _temp = visitsData.past.filter((i) => {
            return inDateRange(new Date(i.dateTime), e[0]._d, e[1]._d);
        });

        dispatch(setPastData(_temp));
    };

    const openOutlook = (e) => {
        if (e.target.id !== 'joinMeeting') {
            window.open('https://outlook.office.com/owa/');
        }
    }

    return (
        <React.Fragment>
            <div className='container-fluid'>
                <div className="sidenav">
                    <SideMenu
                        page="visit"
                    />
                </div>
                <div className="main">
                    <div className="row">
                        <Header
                            title='My Visits'
                        />
                    </div>
                    <div className='border-top' style={{ height: `calc(100% - 5px)` }}>
                        <div className='mt-25'>
                            <ul className="nav nav-tabs" id="myTab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link tab-font active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">
                                        UPCOMING VISITS</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link tab-font" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">
                                        PAST VISITS</button>
                                </li>
                            </ul>
                            <div className="tab-content bg-white" id="myTabContent">
                                <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                    <div className='container-fluid'>
                                        <div className="table-responsive">
                                            {!_isEmpty(visitsData.upcoming) ?
                                                <div>
                                                    <div className='mt-25'>
                                                        <table className="table table-striped table-hover">
                                                            <thead>
                                                                <tr>
                                                                    <th className='w-10' scope="col">
                                                                        <span className={`cursor ${active === 'today' ? 'pop-value' : ''}`} onClick={filterUpcomingDate}>TODAY</span>
                                                                    </th>
                                                                    <th className='w-10' scope="col">
                                                                        <span className={`cursor ${active === 'tomorrow' ? 'pop-value' : ''}`} onClick={filterUpcomingDate}>TOMORROW</span>
                                                                    </th>
                                                                    <th className='w-10' scope="col">
                                                                        <span className={`cursor ${active === 'thisWeek' ? 'pop-value' : ''}`} onClick={filterUpcomingDate}>THIS WEEK</span>
                                                                    </th>
                                                                    <th className='w-10' scope="col">
                                                                        <span className={`cursor ${active === 'thisMonth' ? 'pop-value' : ''}`} onClick={filterUpcomingDate}>THIS MONTH</span>
                                                                    </th>
                                                                    <th scope="col">
                                                                        <div className="float-end">
                                                                            <div>
                                                                                <span className={`float-end cursor ${active === 'customeUpcoming' ? 'pop-value' : ''}`} onClick={filterUpcomingDate}>CUSTOM DATE RANGE <img src={calendarIcon} alt="calendarIcon" /></span>
                                                                            </div>
                                                                            <div>
                                                                                <RangePicker id="datePicker" className={`cursor ${showDatePicker}`} allowClear="true" format="MMM, DD"
                                                                                    disabledDate={disabledDate} value={upcomingDateRange} onChange={setCustomUpcomingDate} />
                                                                            </div>
                                                                        </div>
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                        </table>
                                                    </div>
                                                    {!_isEmpty(upcoming) ?
                                                        <table className="table table-striped table-hover">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col">Contact Person</th>
                                                                    <th scope="col">Visit Type</th>
                                                                    <th scope="col">Subject</th>
                                                                    <th scope="col">Date & Time</th>
                                                                    <th scope="col">Address</th>
                                                                    <th scope="col">Status</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {upcoming.map((e, i) => (
                                                                    <tr className="cursor" key={`upcoming-row-${i}`} onClick={openOutlook}>
                                                                        <th className="text-bold">{e.cPerson}</th>
                                                                        <td className="text-normal">
                                                                            {e.visiType.includes('teams') ? 'Teams Meeting' :
                                                                                e.visiType.includes('zoom') ? 'Zoom Meeting' : e.visiType}
                                                                        </td>
                                                                        <td className="pop-value">{e.subject}</td>
                                                                        <td className="text-normal">
                                                                            <label className="text-bold">{new Date(e.dateTime).toLocaleDateString('en-US', {
                                                                                year: 'numeric', month: 'long', day: 'numeric'
                                                                            })}</label><br></br>
                                                                            <label>{new Date(e.dateTime).toLocaleTimeString('UTC')}</label>
                                                                        </td>
                                                                        <td className="pop-value w-30">
                                                                            {!_isEmpty(e.address) ?
                                                                                <div>
                                                                                    <span className="underline">{e.address.street}</span><br></br>
                                                                                    <span className="underline">
                                                                                        {e.address.city},
                                                                                        {e.address.state},
                                                                                        {e.address.countryOrRegion},
                                                                                        {e.address.postalCode}</span><br></br>
                                                                                </div>
                                                                                :
                                                                                <span>
                                                                                    {e.online ?
                                                                                        <a id="joinMeeting" href={e.online} className="pop-value underline" target="_blank">Join Invite</a>
                                                                                        :
                                                                                        ''
                                                                                    }
                                                                                </span>}
                                                                        </td>
                                                                        <td className="text-normal">{e.status ? e.status[0].toUpperCase() + e.status.substring(1) : ''}</td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                        :
                                                        <div className='mt-25'>
                                                            <label>No Visits</label>
                                                        </div>
                                                    }

                                                </div>
                                                :
                                                <div className='mt-25'>
                                                    <label>No Data to Display</label>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                    <div className='container-fluid'>
                                        <div className="table-responsive">
                                            {!_isEmpty(visitsData.past) ?
                                                <div>
                                                    <div className='mt-25'>
                                                        <table className="table table-striped table-hover">
                                                            <thead>
                                                                <tr>
                                                                    <th className='w-10' scope="col">
                                                                        <span className={`cursor ${activePast === 'yesterday' ? 'pop-value' : ''}`} onClick={filterPastDate}>YESTERDAY</span>
                                                                    </th>
                                                                    <th className='w-10' scope="col">
                                                                        <span className={`cursor ${activePast === 'lastWeek' ? 'pop-value' : ''}`} onClick={filterPastDate}>LAST WEEK</span>
                                                                    </th>
                                                                    <th className='w-10' scope="col">
                                                                        <span className={`cursor ${activePast === 'lastMonth' ? 'pop-value' : ''}`} onClick={filterPastDate}>LAST MONTH</span>
                                                                    </th>
                                                                    <th scope="col">
                                                                        <div className="float-end">
                                                                            <div>
                                                                                <span className={`float-end cursor ${activePast === 'customPastFilter' ? 'pop-value' : ''}`} onClick={filterPastDate}>CUSTOM DATE RANGE <img src={calendarIcon} alt="calendarIcon" /></span>
                                                                            </div>
                                                                            <div>
                                                                                <RangePicker id="datePicker" className={`cursor ${showPastDatePicker}`} allowClear="true" format="MMM, DD"
                                                                                    defaultPickerValue="" value={pastDateRange} onChange={setCustomPastDate} />
                                                                            </div>
                                                                        </div>

                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                        </table>
                                                    </div>
                                                    {!_isEmpty(past) ?
                                                        <table className="table table-striped table-hover">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col">Contact Person</th>
                                                                    <th scope="col">Visit Type</th>
                                                                    <th scope="col">Subject</th>
                                                                    <th scope="col">Date & Time</th>
                                                                    <th scope="col">Address</th>
                                                                    <th scope="col">Status</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {past.map((e, i) => (
                                                                    <tr className="cursor" key={`past-row-${i}`} onClick={openOutlook}>
                                                                        <th className="text-bold">{e.cPerson}</th>
                                                                        <td className="text-normal">
                                                                            {e.visiType.includes('teams') ? 'Teams Meeting' :
                                                                                e.visiType.includes('zoom') ? 'Zoom Meeting' : e.visiType}
                                                                        </td>
                                                                        <td className="pop-value">{e.subject}</td>
                                                                        <td className="text-normal">
                                                                            <label className="text-bold">{new Date(e.dateTime).toLocaleDateString('en-US', {
                                                                                year: 'numeric', month: 'long', day: 'numeric'
                                                                            })}</label><br></br>
                                                                            <label>{new Date(e.dateTime).toLocaleTimeString()}</label>
                                                                        </td>
                                                                        <td className="pop-value w-30">
                                                                            {!_isEmpty(e.address) ?
                                                                                <div>
                                                                                    <span className="underline">{e.address.street}</span><br></br>
                                                                                    <span className="underline">{e.address.city}, {e.address.state},
                                                                                        {e.address.countryOrRegion}, {e.address.postalCode}</span><br></br>
                                                                                </div>
                                                                                :
                                                                                <span >
                                                                                    {e.online ?
                                                                                        <a href={`https://outlook.office.com/owa/`} className="pop-value underline" target="_blank">Join Invite</a>
                                                                                        :
                                                                                        ''
                                                                                    }
                                                                                </span>}
                                                                        </td>
                                                                        <td className="text-normal">{e.status ? e.status[0].toUpperCase() + e.status.substring(1) : ''}</td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                        :
                                                        <div className='mt-25'>
                                                            <label>No Visits</label>
                                                        </div>
                                                    }
                                                </div>
                                                :
                                                <div className='mt-25'>
                                                    <label>No Data to Display</label>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer page="visit" />
            </div>
        </React.Fragment>

    );
}

export default Visits;
