import ACTIONS from "../actions/types.actions";
import appInitialState from "../app-state/app-initial.state";
import building from "./buildings.reducer";
import pitch from "./pitch.reducer";
import user from "./user.reducer";
import visits from "./visits.reducer";

export default function reducer(state = appInitialState, action) {
  if (action.type === ACTIONS.APP_RESET) {
    return appInitialState;
  }
  return {
    user: user(state.user, action),
    building: building(state.building, action),
    pitch: pitch(state.pitch, action),
    visits: visits(state.visits, action)
  };
}
