import _isEmpty from "lodash/isEmpty";
import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from "react-redux";

import calendarIcon from "../assets/img/calendar.svg";
import warehouseIcon from "../assets/img/warehouse.svg";
import PitchetmpIcon from "../assets/img/Pitchetmp.svg";
import ContactIcon from "../assets/img/Contact.svg";

function Footer(props) {

    const user = useSelector(state => state.user.value);

    return (

        <div className='bottom-container'>
            <ul className="nav">
                <li className={`nav-item ${props.page === 'building' ? 'menu-select' : ''}`}>
                    <Link className="nav-link active" aria-current="page" to="/buildingInfo">
                        <img src={warehouseIcon} alt="warehouseIcon" /><br></br>
                        <label className='menu-lable'>Visits Prep</label>
                    </Link>
                </li>
                <li className={`nav-item ${props.page === 'visit' ? 'menu-select' : ''}`}>
                    <Link className="nav-link active" aria-current="page" to="/visits">
                        <img src={calendarIcon} alt="calendarIcon" /><br></br>
                        <label className="menu-lable">Visits</label>
                    </Link>
                </li>
                <li className={`nav-item ${props.page === 'pitch' ? 'menu-select' : ''}`}>
                    <Link className="nav-link active" aria-current="page" to="/pitch-template">
                        <img src={PitchetmpIcon} alt="PitchetmpIcon" /><br></br>
                        <label className="menu-lable">Pitch Temp.</label>
                    </Link>
                </li>
                <li className={`nav-item ${props.page === 'contact' ? 'menu-select' : ''}`}>
                    <Link className="nav-link active" aria-current="page" to="/contacts">
                        <img src={ContactIcon} alt="ContactIcon" />
                        <br></br>
                        <label className='menu-lable'>Contact</label>
                    </Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link active" aria-current="page" to="#">
                        <div className="user-initials">
                            <label>{!_isEmpty(user) ? user.account.name.split(" ").map((n) => n[0]).join("") : ''}</label>
                        </div>
                    </Link>
                </li>
            </ul>
        </div>

    );
}

export default Footer;
