import _isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";
import { Switch, Select, Button } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import {
    getBuildingData,
    getMarketData,
    getNearbyBuildingData,
} from "../../store/actions/buildings.actions";

import { toastMessage } from "../../utils/utils";

import SideMenu from '../../component/SideMenu';
import Header from '../../component/Header';
import Search from '../../component/Search';
import Footer from '../../component/Footer';

import nopath from "../../assets/img/NoPath.png";
import direction from "../../assets/img/direction.svg";

const { Option } = Select;

const SearchPage = ({ history, logout, loading }) => {

    const dispatch = useDispatch();
    let navigate = useNavigate();
    let { state } = useLocation();

    const { searchBuildingData, subMarketDetails } = useSelector(state => state.building);
    const { marketDetails } = useSelector(state => state.user);

    const [searchValue, setSearchValue] = useState('');
    const [open, setOpen] = useState('Open');
    const [marketValue, setMarket] = useState('');
    const [subMarketValue, setSubMarket] = useState('');


    const handleCallback = (callbackValue) => {
        setSearchValue(callbackValue);
        setMarket('')
        setSubMarket('')
        setOpen('Open')
        const location = document.getElementById("loaction-toggle");
        const searchDiv = document.getElementById("market-serch-box");
        location.style.display = "block";
        searchDiv.style.display = "none";
        if (document.getElementsByClassName('ant-select-selection-item').length > 0) {
            console.log(document.getElementsByClassName('ant-select-selection-item'))
            for (var i = 0; i < document.getElementsByClassName('ant-select-selection-item').length; ++i)
                document.getElementsByClassName('ant-select-selection-item')[i].innerText = null;

            // let element = document.querySelector(".ant-select-item-option-selected");
            // element.ariaSelected = "false"
            // element.classList.remove("ant-select-item-option-selected");
        }
    }

    const getBuildingPageData = (e) => {
        localStorage.setItem('bd', JSON.stringify(e.property_code ? e.property_code : e.propertInfo.propertCode));
        navigate("/buildingInfo")
    };

    useEffect(() => {
        if (_isEmpty(searchBuildingData) && !(state) && marketDetails.marketDetails) {
            dispatch(getBuildingData({ "searchData": "" }));
        }
        if (state && state.hasOwnProperty('searchVal')) setSearchValue(state.searchVal);
        navigate({ state: {} })
    }, []);

    const advancesearch = (e) => {
        if (e === 'market') {
            if (open === 'Open') {
                setOpen('Close')
            } else {
                setOpen('Open')
            }

            const location = document.getElementById("loaction-toggle");
            const searchDiv = document.getElementById("market-serch-box");
            if (location.style.display !== "none") {
                location.style.display = "none";
                searchDiv.style.display = "block";
            } else {
                location.style.display = "block";
                searchDiv.style.display = "none";
            }
        }

        if (e === 'location') {
            const location = document.getElementById("market-search");
            if (location.style.display !== "none") {
                location.style.display = "none";
            } else {
                location.style.display = "block";
            }


        }
    };

    const locationSearch = (e) => {
        if (e) {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(function (position) {
                    dispatch(getNearbyBuildingData({ "lat": position.coords.latitude, "log": position.coords.longitude }, false));
                })
            } else {
                toastMessage("info", "Geolocation is not supported by this browser");
            }
        } else {
            dispatch(getBuildingData({ "searchData": "" }));
        }
    }

    const searchMarket = () => {
        if (!marketValue) return toastMessage("info", 'Please select the Market');
        if (!subMarketValue) return toastMessage("info", 'Please select the Sub Market');

        const searchData = document.getElementById("searchBoxVal").value;

        if (searchData.trim())
            dispatch(getBuildingData({ "searchData": searchData, "market_name": marketValue, "submarket_name": subMarketValue }));
        else if (subMarketValue)
            dispatch(getBuildingData({ "market_name": marketValue, "submarket_name": subMarketValue }));
        else
            dispatch(getBuildingData({ "market_name": marketValue }));
        setSearchValue('');
    }

    return (
        <React.Fragment>
            <div className='container-fluid'>
                <div className="sidenav">
                    <SideMenu />
                </div>
                <div className="main pb-100">
                    <div className="row">
                        <Header
                            title='Search'
                        />
                    </div>
                    <div style={{ height: `calc(100% - 5px)` }}>
                        <div className="row">
                            <div className="container-search text-center z-index">
                                <Search
                                    callback={handleCallback}
                                />
                                <div id="loaction-toggle" className="form-check form-switch float-start cursor advance-search-button mt-15">
                                    <Switch checkedChildren="Yes" unCheckedChildren="No" onChange={() => advancesearch('location')} onClick={locationSearch} />
                                    <label className="form-check-label"> &nbsp;&nbsp;Device location based search</label>
                                </div>
                                <label id="market-search" className="float-end cursor advance-search-button mt-15 mr-30" onClick={() => advancesearch('market')}>{open} Advance Search</label>
                            </div>
                            <div id="market-serch-box" className="container-search back-trans pt-0 mt-n display-none">
                                <div className="market-search ml-30 mr-30 border-radius pd-20">
                                    <div className="row">
                                        <div className="col-4">
                                            <label className="market-search-label">Market*</label><br></br>
                                            <Select
                                                showSearch
                                                autoClearSearchValue="false"
                                                className="cursor"
                                                style={{
                                                    width: 250,
                                                }}
                                                placeholder="Select Market"
                                                optionFilterProp="children"
                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                filterSort={(optionA, optionB) =>
                                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                }
                                                // value={marketValue}
                                                onChange={(e) => { setMarket(e) }}
                                                onSelect={(e) => {
                                                    dispatch(getMarketData({ "market_name": e }));
                                                    setSubMarket('');
                                                    if (document.getElementsByClassName('ant-select-selection-item').length > 1)
                                                        document.getElementsByClassName('ant-select-selection-item')[1].innerText = null;
                                                }}
                                            >
                                                {!_isEmpty(marketDetails.marketDetails) ?
                                                    marketDetails.marketDetails.map((e, i) => {
                                                        return (
                                                            <Option key={`market-option${i}`} value={e.MARKET}>{e.MARKET}</Option>
                                                        )
                                                    })
                                                    : ''}
                                            </Select>
                                        </div>
                                        <div className="col-4">
                                            <label className="market-search-label">Sub Market*</label><br></br>
                                            <Select
                                                id="sub-market-select"
                                                showSearch
                                                className="cursor"
                                                style={{
                                                    width: 250,
                                                }}
                                                placeholder="Select Sub Market"
                                                optionFilterProp="children"
                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                filterSort={(optionA, optionB) =>
                                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                }
                                                // value={subMarketValue}
                                                onChange={(e) => { setSubMarket(e) }}
                                            >
                                                {!_isEmpty(subMarketDetails) ?
                                                    subMarketDetails.map((e, i) => {
                                                        return (
                                                            <Option key={`sub-market-option${i}`} value={e.submarket_name}>{e.submarket_name ? e.submarket_name[0].toUpperCase() + e.submarket_name.substring(1) : ''}</Option>
                                                        )
                                                    })
                                                    : ''}
                                            </Select>
                                        </div>
                                        <div className="col-4">
                                            <label className="market-search-label"></label><br></br>
                                            <Button type="primary" className="btn btn-primary float-end button-pri" htmlType="submit" onClick={searchMarket}>
                                                Search
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="container-fluid">
                            <label className='search-title lable-margin-top'>
                                Showing {searchValue ? searchBuildingData.length : ''} result for {searchValue ? `"${searchValue}"` : `${searchBuildingData.length}/${searchBuildingData.length}`}
                            </label>
                        </div>
                        {searchBuildingData.length ?
                            searchBuildingData.map((i, e) => {
                                return (
                                    <div className='row' key={`${e}-search-grid`}
                                        onClick={() => getBuildingPageData(i)}>
                                        <div className='col'>
                                            <div className='search-title-row'>
                                                <img src={nopath} alt="ContactIcon" />
                                                <div className='col-xl-4 col-lg-4 col-md-4 col-sm-4 search-margin-left'>
                                                    <label className='title'>{i.buildingName ? i.buildingName : i.building_name}</label>
                                                    <br></br>
                                                    {i.buildingAdress ?
                                                        <p className='description'>{i.buildingAdress.fullAdress}</p>
                                                        :
                                                        <p className='description'>{i.building_full_address_text}</p>
                                                    }
                                                </div>
                                                <div className='col-xl-2 col-lg-2 col-md-2 col-sm-2 m-top text-center'>
                                                    <label><span className='title'>Enterprise ID:</span> {i.propertInfo ? i.propertInfo.propertCode : i.property_code}</label>
                                                    {/* <br></br> */}
                                                    {/* <p className='description text-center' style={{ marginBottom: '0px' }}>Building status: <span style={{ color: "#008e5b" }}>Active</span></p> */}
                                                </div>
                                                <div className='col m-top'>
                                                    <label className='title'>Customers in this building</label>
                                                    <br></br>
                                                    <div className="card-para">
                                                        {i.buildingTenantContactDetails ?
                                                            i.buildingTenantContactDetails.map((x, y) => {
                                                                return (x.customerName ?
                                                                    <span key={`cust-${y}`} className='description'>{x.customerName}, &nbsp;</span>
                                                                    : '')
                                                            })
                                                            :
                                                            i.customers ? i.customers.map((x, y) => {
                                                                return (x.customer_name ?
                                                                    <span key={`cust-${y}`} className='description'>{x.customer_name}, &nbsp;</span>
                                                                    : '')
                                                            })
                                                                : ''}
                                                    </div>
                                                </div>
                                                {i.distance ?
                                                    < div className='col-xl-2 col-lg-2 col-md-2 col-sm-2 m-top text-center'>
                                                        <img src={direction} className="bblr" alt="direction" />
                                                        <br></br>
                                                        <p className='grid-title mt-10 text-center'>{i.distance === 0 ? '0 Mi' : i.distance ? i.distance.toFixed(2) + ' Mi' : ''}</p>
                                                    </div>
                                                    : ''}
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                            :
                            <div className="container-fluid">
                                <label className="text-normal">No Data Available</label>
                            </div>
                        }
                    </div>
                </div>
                <Footer />
            </div>
        </React.Fragment >
    )
}

SearchPage.propTypes = {
    history: PropTypes.object,
    loading: PropTypes.bool,
    logout: PropTypes.func,
};

export default SearchPage;
