import ACTIONS from "./types.actions";
import _isEmpty from "lodash/isEmpty";
import { AuthService, signIn, signOut } from "../../services/auth.service";
import history from "../../utils/history.utils";
import { to, throwError } from "../../utils/utils";
import UserTemplateService from "../../services/user.services";
import { getBuildingData } from "./buildings.actions";

export function userRequest() {
    return { type: ACTIONS.USER_REQUEST };
}

export function userSuccess(response) {
    return { type: ACTIONS.USER_SUCCESS, response };
}

export function marketDetails(response) {
    return { type: ACTIONS.GET_MARKET_DETAILS, response };
}

export function getAllUsersSuccess(response) {
    return { type: ACTIONS.GET_ALL_USERS_SUCCESS, response };
}

export function userError(error) {
    return { type: ACTIONS.USER_ERROR, error };
}

async function handleEmptyResponse() {
    const currentAccounts = AuthService.getAllAccounts();
    if (!currentAccounts || currentAccounts.length < 1) {
        return signIn({ state: window.location.pathname });
    }
    return AuthService.acquireTokenSilent({
        account: currentAccounts[0],
    });
}

export function checkAuth() {
    return async dispatch => {
        dispatch(userRequest(true));
        let response;
        try {
            if (navigator.onLine) {
                response = await AuthService.handleRedirectPromise();
                if (_isEmpty(response)) {
                    response = await handleEmptyResponse(response);
                    if (!response) throw new Error("No Session Found");
                }
                localStorage.setItem('userInfo', JSON.stringify(response));
                dispatch(userSuccess(response));
            }  else {
                let input = JSON.parse(localStorage.getItem('userInfo'));
                dispatch(userSuccess(input));
            }
            dispatch(fetchMarketDetails());
            if (response.state) history.push(response.state);
        } catch (error) {
            console.log("checkAuthError", error);
            dispatch(userError(error));
        }
    };
}

export function fetchMarketDetails() {
    return async dispatch => {
        try {
            const [err, response] = await to(UserTemplateService.getMarketDetails());
            if (err) throwError(err);
            dispatch(marketDetails(response.message));
            let body = {};
            if (!_isEmpty(response.message) && !_isEmpty(response.message.marketDetails)) {
                body = { 'market_name': response.message.marketDetails[0].MARKET }
            } else {
                body = { 'searchData': "" }
            }
            dispatch(getBuildingData(body));
        } catch (error) {
            console.log(error)
        }
    };
}

export function logout() {
    return async dispatch => {
        dispatch(userRequest());
        const currentAccount = AuthService.getAllAccounts();
        if (currentAccount && currentAccount.length > 0) {
            try {
                const accountId = currentAccount[0].username;
                await signOut(accountId);
            } catch (error) {
                console.log("error", "Unable to Logout, please try again");
            }
        } else {
            console.log("info", "Your session is expired, we are reloading page");
            setTimeout(() => {
                window.location.reload();
            }, 3000);
        }
        return currentAccount;
    };
}