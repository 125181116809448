import React from 'react';
import { useLocation } from 'react-router-dom';

import SideMenu from '../../component/SideMenu';
import Header from '../../component/Header';
import Footer from '../../component/Footer';

function Pitch() {

    const location = useLocation();
    const { pdfLink, title } = location.state;
    let height = window.innerHeight - 155;

    return (
        <React.Fragment>
            <div className='container-fluid'>
                <div className="sidenav">
                    <SideMenu
                        page="pitch"
                    />
                </div>
                <div className="main pb-100">
                    <div className="row">
                        <Header
                            title={title}
                            bookmark={true}
                            bookmarkTitle="Pitch Templates"
                            boomarkLink="/pitch-template"
                        />
                    </div>
                    {pdfLink ?
                        <iframe src={pdfLink} width="100%" height={height} title={title} />
                        :
                        <div className='border-top' style={{ height: `calc(100% - 5px)` }}>
                            <div className='mt-25' style={{ background: '#EFEFEF 0% 0% no-repeat padding-box' }}>
                                <label className="text-normal">No PDF Available</label>
                            </div>
                        </div >
                    }

                </div>
                <Footer page="pitch" />
            </div>

        </React.Fragment>

    );
}

export default Pitch;
