import React, { useState, useEffect } from 'react';
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

import {
    getBuildingData,
} from "../store/actions/buildings.actions";

function Search(props) {
    const dispatch = useDispatch();
    let navigate = useNavigate();
    let { state } = useLocation();

    const [inputValue, setInputValue] = useState("");

    useEffect(() => {
        if (state && state.hasOwnProperty('searchVal')) setInputValue(state.searchVal);
    }, []);

    const onSearch = (e) => {
        if (e.key === 'Enter') {
            dispatch(getBuildingData({"searchData": e.target.value}));
            if (props.callback) props.callback(e.target.value)
            if (props.page) navigate("/", { state: { key: true, searchVal: e.target.value } })
        }
    };

    const handleChange = (e) => {
        setInputValue(e.target.value)
    };

    return (

        <input id="searchBoxVal" className='search-input search-query' type='text' onKeyDown={onSearch} onChange={handleChange}
            placeholder='Type building address, building name, customer name, property code to search' value={inputValue} />

    );
}

export default Search;
