const appInitialState = {
    building: {
        buildingData: {},
        searchBuildingData: [],
        partnerData: [],
        customerdata: {},
        previousSalesPopup: {},
        nearByBuildings: [],
        marketDetails: [],
        subMarketDetails: [],
    },
    user: {
        loading: false,
        error: null,
        value: {},
        marketDetails: [],
    },
    pitch: {
        pitchTemplate: [],
        emailStatus: null
    },
    visits: {
        visitsData: {},
        upcoming: [],
        past: [],
    }
};

export default appInitialState;
