import { produce } from "immer";
import ACTIONS from "../actions/types.actions";

const visits = produce((draft, action) => {
    switch (action.type) {
        case ACTIONS.GET_VISITS_DATA:
            draft.visitsData = action.data;
            return draft;
        case ACTIONS.GET_UPCOMING_DATA:
            draft.upcoming = action.data;
            return draft;
        case ACTIONS.GET_PAST_DATA:
            draft.past = action.data;
            return draft;
        default:
            return draft;
    }
});

export default visits;