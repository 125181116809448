import { get, post } from "./http.service";
import { getSearchListQuery } from "../utils/utils";

const BuildingService = {
    getBuildingData: (body) => get(`buildingdata${getSearchListQuery(body)}`),
    getPartnerDetails: () => get(`partnerlist`),
    getCustomerPreviousSalesData: (body) => get(`customerInteraction${getSearchListQuery(body)}`),
    getCustomerPreviousSalesPopupData: (body) => get(`customerInteraction${getSearchListQuery(body)}`),
    getNearbyBuildingData: (body) => post(`nearestBuildingdata`, body),
    getMarketData: (body) => post(`marketslectionbar`, body),
};
export default BuildingService;
