import { Button, Checkbox, Form, Input } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import _isEmpty from "lodash/isEmpty";
import {
    sendEmailAction,
} from "../store/actions/pitch.actions";

import { toastMessage } from "../utils/utils";

function Modal1(props) {

    const user = JSON.parse(localStorage.getItem('userInfo'));
    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const onFinish = (values) => {

        const body = {
            "fullName": user.account.name,
            "subject": values.subject,
            "toEmail": values.customerEmail,
            "body": values.message,
            "fromEmail": user.account.username,
            "cat": props.pitchList
        };

        let bodyArr = [];
        bodyArr.push(body);
        if (navigator.onLine) {
            dispatch(sendEmailAction(bodyArr));
            setTimeout(() => { form.resetFields(); }, 1000);
        } else {

            if (!window.indexedDB) {
                return toastMessage("info", "Your device doesn't support a stable version of IndexedDB. Offline Email feature will not be available.");
            }

            (async () => {
                //...
                const request = indexedDB.open("esm");

                request.onupgradeneeded = event => {
                    const db = event.target.result;
                    const objStore = db.createObjectStore("emails", { autoIncrement: true });
                };

                request.onsuccess = event => {
                    // Do something with request.result!
                    const db = event.target.result;
                    const tx = db.transaction("emails", "readwrite").objectStore("emails");
                    tx.add(body);
                    setTimeout(() => { form.resetFields(); }, 1000);
                    document.getElementById('cancel').click("toastr.info('Hi! I am info message.')");
                };

            })();

        }
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (

        <div className="modal fade"
            id={props.id} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className={props.className}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">{props.title}</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal"
                            aria-label="Close" style={{ fontSize: "10px" }}></button>
                    </div>
                    <div className="modal-body">
                        <Form
                            name="basic"
                            form={form}
                            labelCol={{
                                span: 8,
                            }}
                            layout="vertical"
                            initialValues={{
                                remember: true,
                            }}
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            autoComplete="off"
                        >
                            <Form.Item
                                label="Customer Email"
                                name="customerEmail"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please provide Customer Email!',
                                    },
                                ]}
                                className="form-label"
                            >
                                <Input type="email" className="form-control" placeholder="jdoe@customername.com" />
                            </Form.Item>

                            <Form.Item
                                label="Email Subject"
                                name="subject"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please provide Subject for Email!',
                                    },
                                ]}
                                className="form-label"
                            >
                                <Input type="text" className="form-control" placeholder="Enter Subject" />
                            </Form.Item>

                            <Form.Item name="message" label="Email Message" className="form-label">
                                <Input.TextArea className="form-control" rows={4} />
                            </Form.Item>

                            <div className="modal-footer block">
                                <Form.Item
                                    wrapperCol={{
                                        offset: 8,
                                        span: 16,
                                    }}
                                >

                                    <Button type="primary" className="btn btn-primary float-end" htmlType="submit">
                                        {props.buttonPrim}
                                    </Button>

                                    <Button id="cancel" type="button" className="btn btn-secondary float-end mr-10" data-bs-dismiss="modal">{props.buttonSec}</Button>
                                </Form.Item>
                            </div>
                        </Form>

                    </div>
                </div>
            </div>
        </div>

    );
}

export default Modal1;
