import { produce } from "immer";
import ACTIONS from "../actions/types.actions";

const building = produce((draft, action) => {
    switch (action.type) {
        case ACTIONS.BUILDING_DATA:
            draft.buildingData = action.data;
            return draft;
        case ACTIONS.SEARCH_BUILDING_DATA:
            draft.searchBuildingData = action.data;
            return draft;
        case ACTIONS.PREVIOUS_SALES_DATA:
            draft.customerdata = action.data;
            return draft;
        case ACTIONS.PREVIOUS_SALES_POPUP_DATA:
            draft.previousSalesPopup = action.data;
            return draft;
        case ACTIONS.PARTNER_DATA:
            draft.partnerData = action.data;
            return draft;
        case ACTIONS.NEAR_BY_BUILDING_DATA: 
            draft.nearByBuildings = action.data;
            return draft;
        case ACTIONS.GET_SUB_MARKET_DETAILS: 
            draft.subMarketDetails = action.data;
            return draft;
        default:
            return draft;
    }
});

export default building;